import { IIcon } from "./types";

const VariablesIcon: React.FC<IIcon> = ({ color }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.63379 4.59375L4.64297 7.39137C3.34781 11.0483 3.42543 15.0512 4.86138 18.6552L5.63379 20.5938"
      stroke={color}
      strokeWidth="1.75"
      strokeLinecap="round"
    />
    <path
      d="M19.6338 4.59375L20.6246 7.39137C21.9198 11.0483 21.8421 15.0512 20.4062 18.6552L19.6338 20.5938"
      stroke={color}
      strokeWidth="1.75"
      strokeLinecap="round"
    />
    <path
      d="M9.63379 9.59375H11.2566C11.4799 9.59375 11.6761 9.74174 11.7374 9.95639L13.5302 16.2311C13.5915 16.4458 13.7877 16.5938 14.0109 16.5938H15.6338"
      stroke={color}
      strokeWidth="1.75"
      strokeLinecap="round"
    />
    <path
      d="M8.63379 16.5938H9.40382C9.54978 16.5938 9.68846 16.53 9.78345 16.4191L15.4841 9.76835C15.5791 9.65753 15.7178 9.59375 15.8638 9.59375L16.6338 9.59375"
      stroke={color}
      strokeWidth="1.75"
      strokeLinecap="round"
    />
  </svg>
);

export default VariablesIcon;
