import { IIcon } from "./types";

const SuperAdminRoleIcon: React.FC<IIcon> = ({ color }) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.51172 6.42492C5.51172 3.7562 7.67515 1.59277 10.3439 1.59277C13.0126 1.59277 15.176 3.7562 15.176 6.42492C15.176 9.09363 13.0126 11.2571 10.3439 11.2571C7.67515 11.2571 5.51172 9.09363 5.51172 6.42492ZM10.3439 3.29277C8.61403 3.29277 7.21172 4.69508 7.21172 6.42492C7.21172 8.15475 8.61403 9.55706 10.3439 9.55706C12.0737 9.55706 13.476 8.15475 13.476 6.42492C13.476 4.69508 12.0737 3.29277 10.3439 3.29277Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3423 12.7349L10.3458 12.7349C10.4666 12.7351 10.5875 12.7381 10.7084 12.7438C11.9574 12.803 13.174 13.1528 14.2618 13.7636C14.6711 13.9934 14.8166 14.5115 14.5868 14.9209C14.3569 15.3302 13.8388 15.4757 13.4295 15.2459C12.5719 14.7643 11.6128 14.4886 10.6281 14.4419M10.0601 14.4419C10.1548 14.4374 10.2495 14.4351 10.344 14.4349L10.0601 14.4419ZM10.344 14.4349C10.4385 14.4351 10.5333 14.4374 10.6281 14.4419L10.344 14.4349ZM10.3423 12.7349C10.2214 12.7351 10.1005 12.7381 9.9797 12.7438L10.3423 12.7349ZM10.0601 14.4419C8.99077 14.4926 7.95172 14.8134 7.04004 15.3744C6.12836 15.9355 5.37373 16.7184 4.84667 17.6502C4.32033 18.5806 4.03806 19.6293 4.02617 20.6982V20.7071H12.7333C13.2028 20.7071 13.5833 21.0877 13.5833 21.5571C13.5833 22.0266 13.2028 22.4071 12.7333 22.4071H3.17617C2.70673 22.4071 2.32617 22.0266 2.32617 21.5571V20.6848C2.34035 19.327 2.69847 17.995 3.367 16.8132C4.03553 15.6313 4.99268 14.6382 6.14907 13.9266M9.9797 12.7438C8.62342 12.808 7.30547 13.215 6.14907 13.9266L9.9797 12.7438Z"
      fill={color}
    />
    <path
      d="M10.3458 12.7349L10.3423 12.7349L9.9797 12.7438C8.62342 12.808 7.30547 13.215 6.14907 13.9266C4.99268 14.6382 4.03553 15.6313 3.367 16.8132C2.69847 17.995 2.34035 19.327 2.32617 20.6848V21.5571C2.32617 22.0266 2.70673 22.4071 3.17617 22.4071H12.7333C13.2028 22.4071 13.5833 22.0266 13.5833 21.5571C13.5833 21.0877 13.2028 20.7071 12.7333 20.7071H4.02617V20.6982C4.03806 19.6293 4.32033 18.5806 4.84667 17.6502C5.37373 16.7184 6.12836 15.9355 7.04004 15.3744C7.95172 14.8134 8.99077 14.4926 10.0601 14.4419C10.1548 14.4374 10.2495 14.4351 10.344 14.4349C10.4385 14.4351 10.5333 14.4374 10.6281 14.4419C11.6128 14.4886 12.5719 14.7643 13.4295 15.2459C13.8388 15.4757 14.3569 15.3302 14.5868 14.9209C14.8166 14.5115 14.6711 13.9934 14.2618 13.7636C13.174 13.1528 11.9574 12.803 10.7084 12.7438C10.5875 12.7381 10.4666 12.7351 10.3458 12.7349Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3915 13.6455C18.2634 13.6456 18.1379 13.682 18.0297 13.7506C17.9215 13.8192 17.8351 13.9171 17.7804 14.033L16.7479 16.1167C16.7452 16.1216 16.7426 16.1266 16.7401 16.1317C16.7351 16.1324 16.7301 16.1333 16.7252 16.1343L14.4516 16.471C14.3227 16.4835 14.2001 16.5328 14.0983 16.613C13.9966 16.6933 13.9201 16.801 13.8778 16.9235C13.8355 17.0459 13.8294 17.1779 13.86 17.3038C13.8906 17.4297 13.9567 17.5441 14.0505 17.6335L15.7149 19.2387L15.7188 19.2419C15.7211 19.2442 15.7229 19.2469 15.7239 19.25C15.7249 19.253 15.7252 19.2563 15.7246 19.2595V19.2621L15.328 21.5818C15.3063 21.7076 15.3202 21.8376 15.3682 21.9558C15.4163 22.0741 15.4965 22.1765 15.5998 22.2514C15.7031 22.3264 15.8254 22.3709 15.9527 22.3798C16.0801 22.3888 16.2073 22.3619 16.3201 22.3022L18.3681 21.2197C18.3755 21.2165 18.3834 21.2148 18.3915 21.2148C18.3996 21.2148 18.4075 21.2165 18.4149 21.2197L20.4629 22.3015C20.5756 22.3616 20.703 22.3889 20.8304 22.3801C20.9579 22.3714 21.0804 22.327 21.1838 22.2521C21.2873 22.1771 21.3677 22.0746 21.4157 21.9562C21.4638 21.8378 21.4776 21.7083 21.4556 21.5825L21.059 19.2614V19.2595C21.0577 19.2569 21.0584 19.253 21.059 19.2504C21.06 19.2472 21.0618 19.2443 21.0642 19.2419L21.0681 19.2387L22.7319 17.6328C22.8254 17.5434 22.8914 17.4291 22.9219 17.3033C22.9524 17.1775 22.9461 17.0456 22.9039 16.9233C22.8617 16.801 22.7852 16.6933 22.6836 16.6131C22.582 16.533 22.4595 16.4837 22.3307 16.471L20.0578 16.1343C20.0529 16.1333 20.0479 16.1324 20.0429 16.1317C20.0404 16.1266 20.0378 16.1216 20.0351 16.1167L19.0026 14.033C18.9479 13.9171 18.8615 13.8192 18.7533 13.7506C18.6451 13.682 18.5196 13.6456 18.3915 13.6455Z"
      fill={color}
    />
  </svg>
);

export default SuperAdminRoleIcon;
