import { IIcon } from "./types";

const MailIcon: React.FC<IIcon> = ({ color }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.50879"
        y="5.46875"
        width="18.25"
        height="14.25"
        rx="1.625"
        stroke={color}
        strokeWidth="1.75"
      />
      <path
        d="M3.63379 6.59375L12.3731 11.9344C12.5331 12.0322 12.7345 12.0322 12.8945 11.9344L21.6338 6.59375"
        stroke={color}
        strokeWidth="1.75"
      />
    </svg>
  );
};

MailIcon.defaultProps = {
  color: "transparent",
};

export default MailIcon;
