interface IAlgorithmIcon {
  color?: string;
}

const AlgorithmIcon: React.FC<IAlgorithmIcon> = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 187 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.4062 32.3281C72.4062 28.8213 69.585 26 66.0781 26C62.5713 26 59.75 28.8213 59.75 32.3281V42.875C50.4424 42.875 42.875 50.4424 42.875 59.75H32.3281C28.8213 59.75 26 62.5713 26 66.0781C26 69.585 28.8213 72.4062 32.3281 72.4062H42.875V87.1719H32.3281C28.8213 87.1719 26 89.9932 26 93.5C26 97.0068 28.8213 99.8281 32.3281 99.8281H42.875V114.594H32.3281C28.8213 114.594 26 117.415 26 120.922C26 124.429 28.8213 127.25 32.3281 127.25H42.875C42.875 136.558 50.4424 144.125 59.75 144.125V154.672C59.75 158.179 62.5713 161 66.0781 161C69.585 161 72.4062 158.179 72.4062 154.672V144.125H87.1719V154.672C87.1719 158.179 89.9932 161 93.5 161C97.0068 161 99.8281 158.179 99.8281 154.672V144.125H114.594V154.672C114.594 158.179 117.415 161 120.922 161C124.429 161 127.25 158.179 127.25 154.672V144.125C136.558 144.125 144.125 136.558 144.125 127.25H154.672C158.179 127.25 161 124.429 161 120.922C161 117.415 158.179 114.594 154.672 114.594H144.125V99.8281H154.672C158.179 99.8281 161 97.0068 161 93.5C161 89.9932 158.179 87.1719 154.672 87.1719H144.125V72.4062H154.672C158.179 72.4062 161 69.585 161 66.0781C161 62.5713 158.179 59.75 154.672 59.75H144.125C144.125 50.4424 136.558 42.875 127.25 42.875V32.3281C127.25 28.8213 124.429 26 120.922 26C117.415 26 114.594 28.8213 114.594 32.3281V42.875H99.8281V32.3281C99.8281 28.8213 97.0068 26 93.5 26C89.9932 26 87.1719 28.8213 87.1719 32.3281V42.875H72.4062V32.3281ZM68.1875 59.75H118.812C123.479 59.75 127.25 63.5205 127.25 68.1875V118.812C127.25 123.479 123.479 127.25 118.812 127.25H68.1875C63.5205 127.25 59.75 123.479 59.75 118.812V68.1875C59.75 63.5205 63.5205 59.75 68.1875 59.75Z"
        fill={color}
      />
    </svg>
  );
};

AlgorithmIcon.defaultProps = {
  color: "transparent",
};

export default AlgorithmIcon;
