import { IIcon } from "./types";

const TargetedPopupsIcon: React.FC<IIcon> = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="dynamic_feed">
      <mask
        id="mask0_382_15609"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect id="Bounding box" width="24" height="24" fill={color} />
      </mask>
      <g mask="url(#mask0_382_15609)">
        <path
          id="dynamic_feed_2"
          d="M4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V12H4V19H13V21H4ZM8 17C7.45 17 6.97917 16.8042 6.5875 16.4125C6.19583 16.0208 6 15.55 6 15V8H8V15H17V17H8ZM12 13C11.45 13 10.9792 12.8042 10.5875 12.4125C10.1958 12.0208 10 11.55 10 11V5C10 4.45 10.1958 3.97917 10.5875 3.5875C10.9792 3.19583 11.45 3 12 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V11C22 11.55 21.8042 12.0208 21.4125 12.4125C21.0208 12.8042 20.55 13 20 13H12ZM12 11H20V7H12V11Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default TargetedPopupsIcon;
