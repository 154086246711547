import { gql } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store";
import useGraphQlLazyQuery from "./useGraphqlLazyQuery";
import { setHasAnalytics, setHasPages } from "@actions";
import {
  setDefaultAnalyticsIntegrationId,
  setDefaultCmsIntegrationId,
  setDefaultEmailingIntegrationId,
  setHasAbTest,
  setHasSkuReport,
  setHasCatalog,
  setHasConditionalRules,
  setHasCustomPlan,
  setHasReport,
  userChangeTheme,
  setHasAlgorithmBuilder,
  setHideMerch,
  setHideReport,
  setHasEmotionAi,
} from "@/services/redux/actions/site-actions";
import { useHasExperiences, useIsMounted } from "@hooks";
import { potionsLocalStorage } from "@/services/LocalStorage/localStorage";
import { EdgeType } from "@/utils/types/requests";
import { client } from "@/services/graphql/apolloConfig";

const GET_HAS_PAGES = gql`
  query site($siteId: SiteID!) {
    site(siteId: $siteId) {
      id
      showPages
      hasMonitor
      hasAbtest
      hasSkuReport
      hasCatalog
      hasAlgorithmBuilder
      defaultEmailingIntegrationId
      hasCustomPlan
      hasConditionalRules
      defaultCmsIntegrationId
      defaultAnalyticsIntegrationId
      company {
        theme
      }
    }
  }
`;

const GET_FLAGS = gql`
  query flags($siteId: SiteID!) {
    flags(siteId: $siteId, first: 1000) {
      edges {
        node {
          siteId
          id
          key
          value
        }
      }
    }
  }
`;

const GET_VALUE_OF_FLAG = gql`
  query FlagByName($siteId: SiteID!, $name: String!) {
    flagByName(siteId: $siteId, name: $name) {
      siteId
      id
      key
      value
    }
  }
`;

const GET_ACTIVE_MULTIPLY_EXPERIENCES = gql`
  query getExperiences($siteId: SiteID) {
    experiences(
      siteId: $siteId
      searchType: "MULTIPLY"
      filters: "is_monitorable = true AND deployed_at != null"
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

export enum FLAG_ENUM {
  hasMonitor = "has_monitor",
  isUnderMaintenance = "is_under_maintenance",
  showPages = "show_pages",
  hasCatalog = "has_catalog",
  hasProducts = "has_products",
  hasCustomPlan = "has_custom_plan",
  hasConditionalRules = "has_conditional_rules",
  hasSkuReport = "has_sku_report",
  hasAlgorithmBuilder = "has_algorithm_builder",
  hideMerch = "hide_merch",
  hideReport = "hide_report",
  hasEmotionAI = "has_emotion_ai",
}

/**
 * Custom React hook to check various properties related to a site.
 *
 * @returns Object containing various properties of the site.
 */
const useHasProperty = () => {
  const isMounted = useIsMounted();
  const { hasExperiences } = useHasExperiences();
  const dispatch = useDispatch();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const hasPages: boolean = useSelector(
    (state: RootState) => state.site.hasPages
  );
  const hasAnalytics: boolean = useSelector(
    (state: RootState) => state.site.hasAnalytics
  );
  const hasAbtest: boolean = useSelector(
    (state: RootState) => state.site.hasAbtest
  );
  const hasSkuReport: boolean = useSelector(
    (state: RootState) => state.site.hasSkuReport
  );
  const hasCatalog: boolean = useSelector(
    (state: RootState) => state.site.hasCatalog
  );
  const hasConditionalRules: boolean = useSelector(
    (state: RootState) => state.site.hasConditionalRules
  );
  const isSuperUser: boolean = useSelector(
    (state: RootState) => state.login.isSuperUser
  );
  const hasMember: boolean =
    isSuperUser || !["561", "504"].includes((siteId ?? "")?.toString());

  const defaultEmailingIntegrationId: ID = useSelector(
    (state: RootState) => state.site.defaultEmailingIntegrationId
  );
  const defaultCmsIntegrationId: ID = useSelector(
    (state: RootState) => state.site.defaultCmsIntegrationId
  );
  const defaultAnalyticsIntegrationId: ID = useSelector(
    (state: RootState) => state.site.defaultAnalyticsIntegrationId
  );
  const hasCustomPlan: boolean = useSelector(
    (state: RootState) => state.site.hasCustomPlan
  );
  const isSelfService: boolean = !hasCustomPlan;

  const hasReport: boolean = useSelector(
    (state: RootState) => state.site.hasReport
  );

  const hideMerch: boolean = useSelector(
    (state: RootState) => state.site.hideMerch
  );
  const hideReport: boolean = useSelector(
    (state: RootState) => state.site.hideReport
  );
  const hasEmotionAi: boolean = useSelector(
    (state: RootState) => state.site.hasEmotionAi
  );

  const isNewLab = false;

  const {
    getDatas,
    data,
    loading: gettingHasPages,
  } = useGraphQlLazyQuery(GET_HAS_PAGES, { siteId }, "no-cache");

  const {
    getDatas: getFlags,
    data: dataFLags,
    loading: gettingHasFlags,
  } = useGraphQlLazyQuery(GET_FLAGS, { siteId }, "no-cache");

  // Custom hook to fetch data using GraphQL queries
  const {
    getDatas: getActiveExperiences,
    data: activeExperiences,
    loading: gettingExperiences,
  } = useGraphQlLazyQuery(
    GET_ACTIVE_MULTIPLY_EXPERIENCES,
    { siteId: parseInt(siteId) },
    "cache-first"
  );

  useEffect(() => {
    if (siteId && isMounted) {
      getDatas();
      getFlags();
      getActiveExperiences();
    }
  }, [siteId]);

  const getValueOfFlag = async (
    flagKey: FLAG_ENUM,
    siteIdentifier: string
  ): Promise<any> => {
    try {
      const res = await client.query({
        query: GET_VALUE_OF_FLAG,
        variables: { siteId: siteIdentifier, name: flagKey },
      });
      return res?.data?.flagByName?.value ?? false;
    } catch (error) {
      console.error("Error fetching flag value:", error);
      throw error;
    }
  };

  // Effect to update Redux store based on fetched data
  useEffect(() => {
    if (data && isMounted) {
      dispatch(
        setDefaultEmailingIntegrationId(
          data?.site?.defaultEmailingIntegrationId
        )
      );
      dispatch(setHasAbTest(!!data?.site?.hasAbtest));
      dispatch(
        setDefaultAnalyticsIntegrationId(
          data?.site?.defaultAnalyticsIntegrationId
        )
      );
      dispatch(setDefaultCmsIntegrationId(data?.site?.defaultCmsIntegrationId));
      dispatch(userChangeTheme(data?.site?.company?.theme));
    }
  }, [data]);

  useEffect(() => {
    if (dataFLags && isMounted) {
      dispatch(
        setHasPages(
          dataFLags?.flags?.edges?.find(
            (edge: EdgeType) => edge.node.key === FLAG_ENUM.showPages
          )?.node?.value ?? false
        )
      );
      dispatch(
        setHasAnalytics(
          siteId > 300 && siteId < 400
            ? false
            : dataFLags?.flags?.edges?.find(
                (edge: EdgeType) => edge.node.key === FLAG_ENUM.hasMonitor
              )?.node?.value ?? false
        )
      );
      dispatch(
        setHasSkuReport(
          dataFLags?.flags?.edges?.find(
            (edge: EdgeType) => edge.node.key === FLAG_ENUM.hasSkuReport
          )?.node?.value ?? false
        )
      );
      dispatch(
        setHasCatalog(
          dataFLags?.flags?.edges?.find(
            (edge: EdgeType) => edge.node.key === FLAG_ENUM.hasCatalog
          )?.node?.value ?? false
        )
      );
      dispatch(
        setHasCustomPlan(
          dataFLags?.flags?.edges?.find(
            (edge: EdgeType) => edge.node.key === FLAG_ENUM.hasCustomPlan
          )?.node?.value ?? false
        )
      );
      dispatch(
        setHasReport(
          siteId > 300 && siteId < 400
            ? false
            : dataFLags?.flags?.edges?.find(
                (edge: EdgeType) => edge.node.key === FLAG_ENUM.hasMonitor
              )?.node?.value ?? false
        )
      );
      dispatch(
        setHasConditionalRules(
          dataFLags?.flags?.edges?.find(
            (edge: EdgeType) => edge.node.key === FLAG_ENUM.hasConditionalRules
          )?.node?.value ?? false
        )
      );
      dispatch(
        setHasAlgorithmBuilder(
          dataFLags?.flags?.edges?.find(
            (edge: EdgeType) => edge.node.key === FLAG_ENUM.hasAlgorithmBuilder
          )?.node?.value ?? false
        )
      );
      dispatch(
        setHideMerch(
          dataFLags?.flags?.edges?.find(
            (edge: EdgeType) => edge.node.key === FLAG_ENUM.hideMerch
          )?.node?.value ?? false
        )
      );
      dispatch(
        setHideReport(
          dataFLags?.flags?.edges?.find(
            (edge: EdgeType) => edge.node.key === FLAG_ENUM.hideReport
          )?.node?.value ?? false
        )
      );
      dispatch(
        setHasEmotionAi(
          dataFLags?.flags?.edges?.find(
            (edge: EdgeType) => edge.node.key === FLAG_ENUM.hasEmotionAI
          )?.node?.value ?? false
        )
      );
    }
  }, [dataFLags]);

  const refreshProperties = () => getDatas();

  return {
    refreshProperties,
    gettingHasPages,
    hasPages,
    hasAnalytics: hasAnalytics && hasExperiences,
    hasAbtest,
    hasSkuReport,
    hasCatalog,
    hasReport,
    defaultEmailingIntegrationId,
    defaultAnalyticsIntegrationId,
    defaultCmsIntegrationId,
    hasCmsTools: !!defaultCmsIntegrationId,
    hasAnalyticsTools: !!defaultAnalyticsIntegrationId,
    loading: gettingExperiences || gettingHasPages,
    hasEmail: !!defaultEmailingIntegrationId,
    isNewLab,
    isOldLab: !isNewLab,
    hasCustomPlan,
    isSelfService,
    hasConditionalRules,
    hasMember,
    getValueOfFlag,
    hideMerch,
    hideReport,
    gettingHasFlags,
    hasEmotionAi,
  };
};

export default useHasProperty;
