import { IIcon } from "./types";

const MerchandisingIcon: React.FC<IIcon> = ({ color }) => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.75408"
        y="5.59292"
        width="10.8069"
        height="18.7931"
        rx="1.625"
        transform="rotate(-19.8684 1.75408 5.59292)"
        stroke={color}
        strokeWidth="1.75"
      />
      <path
        d="M18.125 4.70605V7.66255L16.2924 3.08105H16.5C17.3975 3.08105 18.125 3.80859 18.125 4.70605Z"
        stroke={color}
        strokeWidth="1.75"
      />
    </svg>
  );
};

MerchandisingIcon.defaultProps = {
  color: "transparent",
};

export default MerchandisingIcon;
