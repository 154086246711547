import { IIcon } from "./types";

const CampaignsIcon: React.FC<IIcon> = ({ color }) => (
  <svg
    version="1.1"
    id="Icons"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="25px"
    height="25px"
    fill={color}
    stroke={color}
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <style type="text/css"> </style>{" "}
      <line
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="3"
        y1="11"
        x2="16"
        y2="11"
      ></line>{" "}
      <line
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="16"
        y1="11"
        x2="29"
        y2="11"
      ></line>{" "}
      <line
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="16"
        y1="2"
        x2="16"
        y2="30"
      ></line>{" "}
      <polyline
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        points="16,27 29,27 29,5 16,5 "
      ></polyline>{" "}
      <polyline
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        points="16,5 3,5 3,27 16,27 "
      ></polyline>{" "}
      <path
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M22.5,19H20v-3h2.5c0.8,0,1.5,0.7,1.5,1.5v0C24,18.3,23.3,19,22.5,19z"
      ></path>{" "}
      <path
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M23.5,22H20v-3h3.5c0.8,0,1.5,0.7,1.5,1.5v0C25,21.3,24.3,22,23.5,22z"
      ></path>{" "}
      <polyline
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        points="6,22 9.5,16 13,22 "
      ></polyline>{" "}
      <line
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="7"
        y1="21"
        x2="12"
        y2="21"
      ></line>{" "}
      <line
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="7"
        y1="8"
        x2="7"
        y2="8"
      ></line>{" "}
      <line
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="10"
        y1="8"
        x2="10"
        y2="8"
      ></line>{" "}
      <line
        fill="none"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        x1="13"
        y1="8"
        x2="13"
        y2="8"
      ></line>{" "}
    </g>
  </svg>
);

export default CampaignsIcon;
