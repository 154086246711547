import { Box, useTheme } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ShortUniqueId from "short-unique-id";
import DataGridBox from "../../_Templates/DataGridBox";
import { getLocaleText } from "./helpers";
import { IDataGrid } from "./types";

// /!\ Current page starts at 0
const DataGrid: React.FC<IDataGrid> = ({
  rows,
  columns,
  currentPage,
  pageSize,
  paginationType,
  hasRowClick,
  ...rest
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const uid = new ShortUniqueId();

  const rowsToShow = useMemo(() => {
    if (paginationType === "client")
      return (
        rows?.slice(
          currentPage * pageSize,
          currentPage * pageSize + pageSize
        ) ?? []
      );
    return rows;
  }, [rows, currentPage, pageSize]);

  const orderColumns = useCallback(() => {
    const { columnVisibilityModel } = { ...rest };
    if (columnVisibilityModel && columns?.length > 0) {
      return Object.keys(columnVisibilityModel)
        .map((key: string) =>
          columns.find((column: GridColDef) => column?.field === key)
        )
        .filter((column: GridColDef) => !!column);
    }
    return columns;
  }, [columns]);

  return (
    <Box
      sx={{
        padding: "1px",
        "& .MuiDataGrid-columnHeaders": {
          position: "sticky",
          backgroundColor: `${theme.customPalette.others.layer_1} !important`,
          zIndex: 99,
        },
        "& .MuiDataGrid-virtualScroller": {
          marginTop: "0px !important",
        },
      }}
      data-testid="table-container"
    >
      <DataGridBox
        noFooter={false}
        cursorOnHover={!!hasRowClick}
        maxHeight={undefined}
        minHeight={rows?.length === 0 ? 300 : undefined}
        customStyle={{
          borderRadius: 2,
          overflow: "hidden",
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
            {
              outline: "none",
            },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid",
            borderColor: theme.customPalette.others.background,
          },
        }}
      >
        <DataGridPro
          localeText={getLocaleText(t)}
          columnBuffer={2}
          columnThreshold={2}
          getRowId={() => uid()}
          disableColumnMenu
          autoHeight
          columns={orderColumns()}
          rows={rowsToShow}
          pagination
          isRowSelectable={() => false}
          {...rest}
        />
      </DataGridBox>
    </Box>
  );
};

DataGrid.defaultProps = {
  hasRowClick: false,
};

export default DataGrid;
