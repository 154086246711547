import { closeDrawer } from "@/services/redux/actions";
import { Box, Text } from "@includes";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import DesktopWrapper from "../../../DesktopWrapper";
import MobileWrapper from "../../../MobileWrapper";
import { IIcon } from "./types";
import { textTransform } from "@mui/system";

const Icon: React.FC<IIcon> = ({
  Asset,
  selected,
  onClick,
  tooltipInfos,
  dataTestId,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { title } = tooltipInfos;

  const handleClick = (event: React.SyntheticEvent) => {
    dispatch(closeDrawer());
    onClick(event);
  };

  const [hover, setHover] = useState<boolean>(false);

  return (
    <>
      <DesktopWrapper>
        <Box
          data-tooltip-id={`tooltip-menu-${title}`}
          onClick={onClick}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          sx={{
            cursor: "pointer",
            background: selected
              ? theme.customPalette.bluePurple.slighty
              : "unset",
            p: 1,
            borderRadius: 1,
            "&:hover": {
              "& .MuiTypography-root": {
                color: theme.customPalette.bluePurple.dark,
              },
            },
            transition: "background 0.2s ease-in-out",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "calc(100% - 32px)",
            height: "24px",
            mx: 1,
          }}
          data-testid={dataTestId ? `${dataTestId}` : "menu-icon"}
        >
          <Box
            sx={{
              width: 20,
              height: 20,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexShrink: 0,
            }}
          >
            <Asset
              color={
                selected || hover
                  ? theme.customPalette.bluePurple.main
                  : theme.customPalette.grey.default
              }
            />
          </Box>
          <Text
            noWrap
            customStyle={{
              ml: 1,
              color: selected && theme.customPalette.bluePurple.dark,
              textTransform: "uppercase",
            }}
            variant="body2"
          >
            {title}
          </Text>
        </Box>
      </DesktopWrapper>
      <MobileWrapper>
        <Box onClick={handleClick} sx={{ width: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              background: selected
                ? theme.customPalette.others.background
                : "transparent",
              padding: "4px 0 4px 4px",
              width: "95%",
              borderRadius: 2,
            }}
          >
            <Asset
              color={
                selected || hover
                  ? theme.customPalette.bluePurple.main
                  : theme.customPalette.grey.default
              }
            />
            <Text variant="body2" customStyle={{ textTransform: "uppercase" }}>
              {title}
            </Text>
          </Stack>
        </Box>
      </MobileWrapper>
    </>
  );
};

export default Icon;
