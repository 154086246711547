import SearchBar from "@/components/_Atoms/SearchBar";
import { Button } from "@/includes";
import { Box, FormControlLabel, Menu, Switch, useTheme } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LabelType } from "../../types";
import { ToolbarProps } from "./types";

const Toolbar = ({
  search,
  setSearch,
  labels,
  selectedLabels,
  handleChangeSelectedLabel,
}: ToolbarProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer
      sx={{
        px: 1,
        pt: 2,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: theme.customPalette.white.main,
        gap: 2,
      }}
    >
      <Box sx={{ flex: 1, mb: 2 }}>
        <SearchBar search={search} setSearch={setSearch} />
      </Box>
      {labels?.length > 0 && (
        <Button
          btnVariant="outline"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          customStyle={{ mb: 2 }}
        >
          {t("filter")}
        </Button>
      )}
      {labels?.length > 0 && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Box sx={{ px: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeSelectedLabel(-1)
                  }
                  checked={selectedLabels.includes(-1)}
                />
              }
              label={t("without_label")}
            />
          </Box>
          {labels.map((label: LabelType) => (
            <Box sx={{ px: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeSelectedLabel(label.id)
                    }
                    checked={selectedLabels.includes(label.id)}
                  />
                }
                label={label.name}
              />
            </Box>
          ))}
        </Menu>
      )}
    </GridToolbarContainer>
  );
};

export default Toolbar;
