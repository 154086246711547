import { IIcon } from "./types";

const CatalogIcon: React.FC<IIcon> = ({ color }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6338 7.31597L11.5324 6.82647C9.02435 5.71178 6.19016 5.57496 3.58639 6.44288L2.97568 6.64645C2.7715 6.71451 2.63379 6.90558 2.63379 7.1208V19.4C2.63379 19.7413 2.96814 19.9823 3.2919 19.8744L4.15528 19.5866C6.41322 18.8339 8.85436 18.8339 11.1123 19.5866L12.6338 20.0938M12.6338 7.31597L13.7352 6.82647C16.2432 5.71178 19.0774 5.57496 21.6812 6.44288L22.2919 6.64645C22.4961 6.71451 22.6338 6.90558 22.6338 7.1208V19.4C22.6338 19.7413 22.2994 19.9823 21.9757 19.8744L21.1123 19.5866C18.8544 18.8339 16.4132 18.8339 14.1553 19.5866L12.6338 20.0938M12.6338 7.31597V20.0938"
        stroke={color}
        strokeWidth="1.75"
      />
    </svg>
  );
};

CatalogIcon.defaultProps = {
  color: "transparent",
};

export default CatalogIcon;
