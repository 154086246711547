import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { DataGrid, SwitchIcon } from "@includes";
import { useTheme } from "@mui/material";
import { useParams } from "react-router";
import { useCatalogColumnsVisibilityModel, usePersistedState } from "@hooks";
import Slide from "./components/Slide";
import List from "./components/List";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";
import DataGridClientPagination from "../DataGridClientPagination";
import productsColumns from "./columns";
import useCatalogColumns from "@/hooks/useCatalogColumns";
import { IProductList } from "./types";
import { a11yProps } from "./helpers";

const ProductList: React.FC<IProductList> = ({
  visibleFields,
  isLoading,
  products,
  isSlider,
  list,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const { siteId } = useParams<UrlParams>();
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const catalogColumns = useSelector(
    (state: RootState) => state.catalog.catalogColumns
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabStyle = {
    textTransform: "unset",
    gap: "10px",
  };

  const additionnalColumnsVisibilityModel: Dic<boolean> = {};

  catalogColumns.forEach((cCol: Dic<string>) => {
    additionnalColumnsVisibilityModel[t(cCol.name)] = true;
  });

  const { columnVisibilityModel, setColumnVisibilityModel } =
    useCatalogColumnsVisibilityModel();

  const [productPerRow, setProductPerRow] = usePersistedState({
    key: "productPerRow",
    initialValue: 5,
  });

  const [nbProductToShow, setNbProductToShow] = React.useState(30);

  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = usePersistedState({
    key: "productsListPageSize-1",
    initialValue: 100,
  });

  const columns = React.useMemo(() => {
    return productsColumns(
      theme,
      columnVisibilityModel,
      setColumnVisibilityModel,
      t,
      currency,
      siteId ?? ""
    );
  }, [theme, columnVisibilityModel]);

  const { cols } = useCatalogColumns(columns, currency);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          borderBottom: 1,
          borderColor: "divider",
          "& .MuiTabs-root": {
            minHeight: "unset",
          },
          "& .Mui-selected": {
            color: `${theme.customPalette.grey.default} !important`,
          },
          "& .MuiButtonBase-root": {
            minHeight: "30px",
            height: "fit-content",
            fontSize: "1rem",
            alignItems: "center",
            padding: "4px 16px 4px 0",
            textTransform: "capitalize",
          },
          "& .MuiTabs-flexContainer": {
            alignItems: "end",
            height: "100%",
          },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="product list"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.customPalette.bluePurple.dark,
            },
          }}
        >
          <Tab
            label={t("preview_view")}
            {...a11yProps(0)}
            sx={{ ...tabStyle }}
            icon={<SwitchIcon icon="previewlist" disabled={value !== 0} />}
            iconPosition="start"
          />
          <Tab
            label={t("table_view")}
            {...a11yProps(1)}
            sx={{ ...tabStyle }}
            icon={<SwitchIcon icon="tableview" disabled={value !== 1} />}
            iconPosition="start"
          />
        </Tabs>
      </Box>
      <Box sx={{ margin: "auto", minHeight: 300 }}>
        {value === 1 && (
          <DataGrid
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            pageSize={pageSize}
            currentPage={currentPage}
            paginationType="client"
            columns={cols}
            rowCount={products?.length}
            rows={products ?? []}
            loading={isLoading}
            componentsProps={{
              pagination: {
                totalItems: products.length,
                pageSize,
                handleChangePageSize: setPageSize,
                currentPage,
                handleChangeCurrentPage: setCurrentPage,
              },
            }}
            components={{
              Pagination: DataGridClientPagination,
            }}
          />
        )}
        {value === 0 && isSlider && (
          <Slide isLoading={isLoading} list={list} products={products} />
        )}

        {value === 0 && !isSlider && (
          <List
            setNbProductToShow={setNbProductToShow}
            nbProductToShow={nbProductToShow}
            isLoading={isLoading}
            list={list}
            products={products}
            productsPerRow={productPerRow}
            setProductsPerRow={setProductPerRow}
          />
        )}
      </Box>
    </Box>
  );
};

ProductList.defaultProps = {
  isSlider: true,
  list: "products",
};

export default ProductList;
