import { gql } from "@apollo/client";

export const GET_EXPERIENCES = gql`
  query getExperiences(
    $siteId: SiteID
    $cursor: String
    $first: Int!
    $searchType: String
    $search: String
    $sort: String
    $labels: [LabelID!]
  ) {
    experiences(
      siteId: $siteId
      searchType: $searchType
      after: $cursor
      first: $first
      desc: false
      search: $search
      sort: $sort
      labels: $labels
    ) {
      edges {
        node {
          isMonitorable
          isConfigurable
          settings
          id
          uuid
          name
          lastUpdate
          deployedAt
          updatedAt
          type
          onMobile
          onDesktop
          pageType
          analyticsName
          locationId
          previews {
            edges {
              node {
                link
              }
            }
          }
          collection {
            id
            name
            settings
          }
          labels {
            label {
              siteId
              id
              name
              backgroundColor
              textColor
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

export const GET_COLLECTION = gql`
  query collection($id: CollectionID) {
    collection(collectionId: $id) {
      name
      description
      settings
    }
  }
`;

export const CREATE_COLLECTION = gql`
  mutation createCollection(
    $name: String!
    $id: SiteID!
    $description: String
    $settings: JSON
  ) {
    createCollection(
      collection: {
        name: $name
        description: $description
        settings: $settings
      }
      siteId: $id
    ) {
      id
    }
  }
`;

export const CREATE_EXPERIENCE = gql`
  mutation createExperience($siteId: SiteID!, $experience: ExperienceInput!) {
    createExperience(siteId: $siteId, experience: $experience) {
      id
      runId
      name
    }
  }
`;
