import useRole from "@/hooks/useRole";
import { userLogout } from "@actions";
import { MenuItem, Popover, Stack, Text } from "@includes";
import { Divider, useTheme } from "@mui/material";
import { RootState } from "@store";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IAvatarPopover } from "./types";

const AvatarPopover: React.FC<IAvatarPopover> = ({ anchorEl, handleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { role } = useRole();

  const { t }: i18translateType = useTranslation();
  const open = Boolean(anchorEl);
  const user = useSelector((state: RootState) => state.login.user);
  const { email } = user;

  const logout = () => {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/auth/logout`,
    }).finally(() => {
      dispatch(userLogout());
    });
  };

  return (
    <Popover
      id="avatar-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          background: theme.customPalette.others.layer_1,
          border: "1px solid",
          borderColor: theme.customPalette.others.background,
        },
      }}
    >
      <MenuItem
        disableRipple
        key="email"
        sx={{
          cursor: "inherit",
          color: theme.customPalette.grey.default,
          "&:hover": {
            backgroundColor: "transparent",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Text>{`${t("welcome")}, ${email}`}</Text>
        <Stack direction="row" alignItems="center">
          <Text>{t("you_are")}</Text>
          <Text
            customStyle={{
              color: theme.customPalette.bluePurple.light,
              ml: 0.5,
            }}
          >
            {t(role)}
          </Text>
        </Stack>
      </MenuItem>
      {/* <Divider />
      <AvatarMenuItem
        Icon={OrganizationIcon}
        label="organization"
        link="https://app2.abtasty.com/settings/organization-informations"
      />
      <AvatarMenuItem
        Icon={AccountIcon}
        label="account"
        link="https://app2.abtasty.com/settings/account-informations"
      />
      <AvatarMenuItem
        Icon={ProfileIcon}
        label="profile"
        link="https://app2.abtasty.com/settings/user-informations"
      />
      <AvatarMenuItem
        Icon={ImplementationIcon}
        label="implementation"
        link="https://app2.abtasty.com/settings/installation-code/tag"
      />
      <AvatarMenuItem
        Icon={SettingsIcon}
        label="advanced_settings"
        link="https://app2.abtasty.com/settings/tracking/1-visitor-1-test"
      />
      <Stack sx={{ mt: 1 }} /> */}
      <Divider />
      <MenuItem
        key="logout"
        sx={{
          cursor: "pointer",
          "&:hover": {
            "& .MuiTypography-root": {
              color: theme.customPalette.bluePurple.dark,
            },
            backgroundColor: "inherit",
          },
        }}
        onClick={logout}
      >
        <Text type="error">{t("sign_out")}</Text>
      </MenuItem>
    </Popover>
  );
};

export default AvatarPopover;
