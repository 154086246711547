import { IIcon } from "./types";

const HomeIcon: React.FC<IIcon> = ({ color }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4978 2.41196C12.2182 2.4493 12.5019 2.17738 6.8574 7.8194C1.06287 13.6114 1.4701 13.1836 1.43542 13.5153C1.36224 14.2156 2.14872 14.7059 2.73449 14.3251C2.80049 14.2822 3.24324 13.8572 3.71837 13.3807C4.1935 12.9041 4.59039 12.5142 4.60037 12.5142C4.61033 12.5142 4.61849 14.4092 4.61849 16.7253C4.61849 20.9255 4.61861 20.9367 4.6682 21.0692C4.72973 21.2337 4.92082 21.4483 5.0756 21.5267C5.13804 21.5584 5.25783 21.5954 5.3418 21.6091C5.55411 21.6435 19.7149 21.6435 19.9272 21.6091C20.0112 21.5954 20.1302 21.5588 20.1917 21.5276C20.3311 21.4569 20.5173 21.2605 20.5862 21.1114L20.6385 20.9982L20.6448 16.7562C20.6482 14.4231 20.6591 12.5142 20.6689 12.5142C20.6787 12.5142 21.0755 12.9041 21.5506 13.3807C22.0257 13.8572 22.4685 14.2822 22.5345 14.3251C23.0242 14.6435 23.702 14.3533 23.819 13.7753C23.854 13.6022 23.8354 13.4159 23.7663 13.2494C23.7213 13.1407 23.0965 12.5068 18.4809 7.88676C15.602 5.00508 13.1959 2.60976 13.134 2.56383C12.9535 2.42976 12.7421 2.37932 12.4978 2.41196ZM9.47247 7.6242L6.32249 10.7743V15.3402V19.9062H12.6345H18.9465V15.3402V10.7741L15.7965 7.62416C14.0639 5.89167 12.641 4.47418 12.6344 4.47418C12.6278 4.47418 11.2049 5.89169 9.47247 7.6242Z"
      fill={color}
    />
  </svg>
);

export default HomeIcon;
