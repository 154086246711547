import { IIcon } from "./types";

const ReportIcon: React.FC<IIcon> = ({ color }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.50879"
        y="3.46875"
        width="18.25"
        height="18.25"
        rx="1.625"
        stroke={color}
        strokeWidth="1.75"
      />
      <path
        d="M7.63379 11.5938L7.63379 17.5938"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <path
        d="M12.6338 7.59375L12.6338 17.5938"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <path
        d="M17.6338 15.5938L17.6338 17.5938"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
      />
    </svg>
  );
};

ReportIcon.defaultProps = {
  color: "transparent",
};

export default ReportIcon;
