export enum VARIABLE_TYPE_ENUM {
  STRING = "STRING",
  ARRAY_STRING = "ARRAY_STRING",
  BOOLEAN = "BOOLEAN",
  ARRAY_BOOLEAN = "ARRAY_BOOLEAN",
  NUMBER = "NUMBER",
  ARRAY_NUMBER = "ARRAY_NUMBER",
  ITEM_ID = "ITEM_ID",
  ARRAY_ITEM_ID = "ARRAY_ITEM_ID",
  CATEGORY_ID = "CATEGORY_ID",
  ARRAY_CATEGORY_ID = "ARRAY_CATEGORY_ID",
  USER = "USER",
  TAG = "TAG",
  SESSION_ID = "SESSION_ID",
  EMOTION = "EMOTION",
}

export const VARIABLE_NEED_PROPERTY_OF_DATASET: Dic<string> = {
  [VARIABLE_TYPE_ENUM.ITEM_ID]: "item",
  [VARIABLE_TYPE_ENUM.USER]: "user",
  [VARIABLE_TYPE_ENUM.SESSION_ID]: "session",
};
