import { useViewport } from "@/hooks";
import { Box, useTheme } from "@mui/material";

const Separator = () => {
  const theme = useTheme();
  const { isMobile } = useViewport();
  return (
    <Box
      sx={{
        width: "100%",
        height: 2,
        background: theme.customPalette.others.background,
        my: isMobile ? 0 : 2,
      }}
    />
  );
};

export default Separator;
