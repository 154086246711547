import { IIcon } from "./types";

const LocationsIcon: React.FC<IIcon> = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 194 242"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M96.6667 120.667C101.113 120.667 104.918 119.087 108.084 115.928C111.25 112.768 112.833 108.97 112.833 104.533C112.833 100.097 111.25 96.2986 108.084 93.1392C104.918 89.9797 101.113 88.4 96.6667 88.4C92.2208 88.4 88.415 89.9797 85.249 93.1392C82.083 96.2986 80.5 100.097 80.5 104.533C80.5 108.97 82.083 112.768 85.249 115.928C88.415 119.087 92.2208 120.667 96.6667 120.667ZM96.6667 179.957C113.103 164.899 125.295 151.219 133.244 138.918C141.192 126.616 145.167 115.692 145.167 106.147C145.167 91.4922 140.485 79.493 131.122 70.1492C121.759 60.8053 110.274 56.1333 96.6667 56.1333C83.0598 56.1333 71.5746 60.8053 62.2115 70.1492C52.8483 79.493 48.1667 91.4922 48.1667 106.147C48.1667 115.692 52.141 126.616 60.0896 138.918C68.0382 151.219 80.2306 164.899 96.6667 179.957ZM96.6667 201.333C74.9764 182.915 58.776 165.806 48.0656 150.009C37.3552 134.212 32 119.591 32 106.147C32 85.98 38.5004 69.9139 51.501 57.9483C64.5017 45.9828 79.5569 40 96.6667 40C113.777 40 128.832 45.9828 141.832 57.9483C154.833 69.9139 161.333 85.98 161.333 106.147C161.333 119.591 155.978 134.212 145.268 150.009C134.557 165.806 118.357 182.915 96.6667 201.333Z"
      fill={color}
    />
  </svg>
);

export default LocationsIcon;
