import { Box, Stack, Tag, Tendances, Text, Tooltip } from "@includes";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { GridRowParams } from "@mui/x-data-grid";
import { formatUtils } from "@utils";
import { StringMap, TOptions } from "i18next";

function productsColumns(
  theme: Dic<any>,
  t: (text: string, options?: TOptions<StringMap>) => string,
  currency: string = "€",
  siteId: string
): any {
  return [
    {
      field: "image",
      headerName: "Image",
      minWidth: 40,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: (params: GridRowParams) => (
        <Box
          sx={{ width: 25, height: 25 }}
          component="img"
          src={`/${theme.customPalette.mode}/others/image.svg`}
        />
      ),
      renderCell: (params: GridRowParams) => (
        <Box sx={{ position: "relative", height: "90%" }}>
          <Box
            sx={{ height: "90%" }}
            component="img"
            src={params.row.imgLink}
          />
        </Box>
      ),
      align: "left",
    },
    {
      field: "id",
      sortable: false,
      align: "left",
      width: 90,
      disableColumnMenu: true,
      headerAlign: "left",
      renderHeader: () => (
        <Text textType="secondary" isTableHeader variant="body2">
          #ID
        </Text>
      ),
      renderCell: (params: GridRowParams) => (
        <Text variant="body2" noWrap>
          {params.row.id}
        </Text>
      ),
    },
    {
      field: "title",
      headerName: t("product_name"),
      width: 180,
      sortable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            component="img"
            src={`/${theme.customPalette.mode}/products/name.svg`}
          />
          <Text variant="body2" textType="secondary" isTableHeader>
            {t("product_name")}
          </Text>
        </Stack>
      ),
      renderCell: (params: GridRowParams) => (
        <Tooltip title={params.row.title ?? ""}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Text variant="body2" noWrap>
              {params.row.title}
            </Text>
          </div>
        </Tooltip>
      ),
      valueGetter: (params: GridRowParams) => params.row.title,
    },
    {
      field: "discountedPrice",
      headerName: t("stroke_price"),
      width: 160,
      sortable: false,
      align: "left",
      renderCell: (params: GridRowParams) => (
        <Text variant="body2">
          {formatUtils.formatPrice(params.row.discountedPrice, currency)}
        </Text>
      ),
      headerAlign: "left",
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            component="img"
            src={`/${theme.customPalette.mode}/products/price.svg`}
          />
          <Text variant="body2" textType="secondary" isTableHeader>
            {t("stroke_price")}
          </Text>
        </Stack>
      ),
      valueGetter: (params: GridRowParams) => params.row.discountedPrice,
    },
    {
      field: "salePrice",
      headerName: t("sale_price"),
      width: 160,
      sortable: false,
      align: "left",
      renderCell: (params: GridRowParams) => (
        <Text variant="body2">
          {formatUtils.formatPrice(params.row.salePrice, currency)}
        </Text>
      ),
      headerAlign: "left",
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            component="img"
            src={`/${theme.customPalette.mode}/products/price.svg`}
          />
          <Text variant="body2" textType="secondary" isTableHeader>
            {t("sale_price")}
          </Text>
        </Stack>
      ),
      valueGetter: (params: GridRowParams) => params.row.salePrice,
    },
    {
      field: "priceBeforeDiscount",
      headerName: t("priceBeforeDiscount"),
      width: 160,
      sortable: false,
      align: "left",
      renderCell: (params: GridRowParams) => (
        <Text variant="body2">
          {formatUtils.formatPrice(params.row.priceBeforeDiscount, currency)}
        </Text>
      ),
      headerAlign: "left",
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            component="img"
            src={`/${theme.customPalette.mode}/products/price.svg`}
          />
          <Text variant="body2" textType="secondary" isTableHeader>
            {t("priceBeforeDiscount")}
          </Text>
        </Stack>
      ),
      valueGetter: (params: GridRowParams) => params.row.priceBeforeDiscount,
    },
    {
      field: "category",
      headerName: t("category"),
      width: 230,
      sortable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            component="img"
            src={`/${theme.customPalette.mode}/products/category.svg`}
          />
          <Text variant="body2" textType="secondary" isTableHeader>
            {t("category")}
          </Text>
        </Stack>
      ),
      renderCell: (params: GridRowParams) => (
        <Tooltip title={params.row.category ?? ""}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Text variant="body2" noWrap customStyle={{ width: 220 }}>
              {params.row.category}
            </Text>
          </div>
        </Tooltip>
      ),
      valueGetter: (params: GridRowParams) => params.row.category,
    },

    {
      field: "Status",
      headerName: t("status"),
      width: 150,
      align: "left",
      headerAlign: "left",
      sortable: false,
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            component="img"
            src={`/${theme.customPalette.mode}/products/availability.svg`}
          />
          <Text variant="body2" textType="secondary" isTableHeader>
            {t("status")}
          </Text>
        </Stack>
      ),
      renderCell: (params: GridRowParams) => (
        <Tag tagVariant={params.row.inactive ? "error" : "success"}>
          {params.row.inactive ? t("inactif") : t("actif")}
        </Tag>
      ),
    },
    {
      field: "Stock per size",
      headerName: t("stock_per_size"),
      width: 150,
      align: "left",
      headerAlign: "left",
      sortable: false,
      renderHeader: () => (
        <div>
          <Tooltip title={t("stock_per_size_tooltip")}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/availability.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("stock_per_size")}
              </Text>
            </Stack>
          </Tooltip>
        </div>
      ),
      renderCell: (params: GridRowParams) => (
        <Text>{params.row.formattedStockPerSize}</Text>
      ),
    },
    {
      field: "Availability",
      headerName: t("in_stock"),
      width: 150,
      align: "left",
      headerAlign: "left",
      sortable: false,
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            component="img"
            src={`/${theme.customPalette.mode}/products/availability.svg`}
          />
          <Text variant="body2" textType="secondary" isTableHeader>
            {t("available")}
          </Text>
        </Stack>
      ),
      renderCell: (params: GridRowParams) => (
        <Tag
          tagVariant={
            params.row.availability === "In stock" ? "success" : "error"
          }
        >
          {t(params.row.availability)}
        </Tag>
      ),
    },
    {
      field: "Last 30 days views",
      type: "number",
      sortable: false,
      width: 150,
      align: "left",
      headerAlign: "left",
      headerName: t("last_30_days_views"),
      valueGetter: (params: GridRowParams) => params.row.lastMonthViews,
      renderHeader: () => (
        <Tooltip title={t("last_30_days_views_tooltip")}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/views.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("last_30_days_views")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Text variant="body2" noWrap>
          {params.row.lastMonthViews}
        </Text>
      ),
    },
    {
      field: "Last 14 days views",
      type: "number",
      width: 150,

      sortable: false,
      align: "left",
      headerAlign: "left",
      headerName: t("last_14_days_views"),
      valueGetter: (params: GridRowParams) =>
        params.row.enrichedData?.["14_days_views"],
      renderHeader: () => (
        <Tooltip title={t("last_14_days_views_tooltip")}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/views.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("last_14_days_views")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Text variant="body2" noWrap>
          {params.row.enrichedData?.["14_days_views"]}
        </Text>
      ),
    },
    {
      field: "Last 7 days views",
      type: "number",
      width: 150,

      sortable: false,
      align: "left",
      headerAlign: "left",
      headerName: t("last_7_days_views"),
      renderHeader: () => (
        <Tooltip title={t("last_7_days_views_tooltip")}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/views.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("last_7_days_views")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text variant="body2" noWrap>
            {params.row.enrichedData?.["7_days_views"]}
          </Text>
          <Tendances tendanceValues={params.row.last7DaysViewsTendencyValues} />
        </Stack>
      ),
      valueGetter: (params: GridRowParams) =>
        params.row.enrichedData?.["7_days_views"],
    },
    {
      field: "Visibility rank",
      headerName: t("visibility_rank"),
      width: 150,
      sortable: false,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <Tooltip title={t("visibility_rank_tooltip")}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/visibility.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("visibility_rank")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Text variant="body2" noWrap>
          {params.row.visibilityRank}
        </Text>
      ),

      valueGetter: (params: GridRowParams) => params.row.visibilityRank,
    },
    {
      field: "Visibility score",
      width: 150,

      sortable: false,
      align: "left",
      headerAlign: "left",
      headerName: t("visibility_score"),
      renderHeader: () => (
        <Tooltip title={t("visibility_score_tooltip")}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/visibility.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("visibility_score")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Text variant="body2" noWrap>
            {formatUtils.formatToPercent(params.row.visibilityScore)}
          </Text>
        </Stack>
      ),
      valueGetter: (params: GridRowParams) => params.row.visibilityScore,
    },
    {
      field: "Last 30 days transactions",
      type: "number",
      width: 150,

      sortable: false,
      align: "left",
      headerAlign: "left",
      headerName: t("last_30_days_transactions"),
      renderHeader: () => (
        <Tooltip title={t("last_30_days_transactions_tooltip")}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/price.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("last_30_days_transactions")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Text variant="body2" noWrap>
          {params.row.enrichedData?.["30_days_transactions"]}
        </Text>
      ),

      valueGetter: (params: GridRowParams) =>
        params.row.enrichedData?.["30_days_transactions"],
    },
    {
      field: "Last 14 days transactions",
      type: "number",
      width: 150,

      sortable: false,
      align: "left",
      headerAlign: "left",
      headerName: t("last_14_days_transactions"),
      renderHeader: () => (
        <Tooltip title={t("last_14_days_transactions_tooltip")}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/price.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("last_14_days_transactions")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Text variant="body2" noWrap>
          {params.row.enrichedData?.["14_days_transactions"]}
        </Text>
      ),

      valueGetter: (params: GridRowParams) =>
        params.row.enrichedData?.["14_days_transactions"],
    },
    {
      field: "Last 7 days transactions",
      type: "number",
      width: 150,

      sortable: false,
      align: "left",
      headerAlign: "left",
      headerName: t("last_7_days_transactions"),
      renderHeader: () => (
        <Tooltip title={t("last_7_days_transactions_tooltip")}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/price.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("last_7_days_transactions")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text variant="body2" noWrap>
            {params.row.enrichedData?.["7_days_transactions"]}
          </Text>
          <Tendances
            tendanceValues={params.row.last7DaysTransactionsTendencyValues}
          />
        </Stack>
      ),

      valueGetter: (params: GridRowParams) =>
        params.row.enrichedData?.["7_days_transactions"],
    },
    {
      field: "Popularity rank",
      width: 150,

      sortable: false,
      align: "left",
      headerAlign: "left",
      headerName: t("popularity_rank"),
      renderHeader: () => (
        <Tooltip title={t("popularity_rank_tooltip")}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/popularity.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("popularity_rank")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Text variant="body2" noWrap>
          {params.row.popularityRank}
        </Text>
      ),

      valueGetter: (params: GridRowParams) => params.row.popularityRank,
    },
    {
      field: "Popularity score",
      width: 150,

      sortable: false,
      align: "left",
      headerAlign: "left",
      headerName: t("popularity_score"),
      renderHeader: () => (
        <Tooltip
          title={t("popularity_score_tooltip", {
            days: siteId === "204" ? 90 : 30,
          })}
        >
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/popularity.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("popularity_score")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Text variant="body2" noWrap>
            {formatUtils.formatToPercent(params.row?.popularityScore)}
          </Text>
        </Stack>
      ),
      valueGetter: (params: GridRowParams) => params.row?.popularityScore,
    },
    {
      field: "Last 30 days revenue",
      type: "number",
      width: 150,

      sortable: false,
      align: "left",
      headerAlign: "left",
      headerName: t("last_30_days_revenue"),
      renderHeader: () => (
        <Tooltip title={t("last_30_days_revenue")}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/revenue.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("last_30_days_revenue")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Text variant="body2" noWrap>
          {formatUtils.formatCurrency(
            params.row.enrichedData?.["30_days_revenue"]
          )}
        </Text>
      ),
      valueGetter: (params: GridRowParams) =>
        params.row.enrichedData?.["30_days_revenue"],
    },
    {
      field: "Last 14 days revenue",
      type: "number",
      width: 150,

      sortable: false,
      align: "left",
      headerAlign: "left",
      headerName: t("last_14_days_revenue"),
      renderHeader: () => (
        <Tooltip title={t("last_14_days_revenue")}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/revenue.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("last_14_days_revenue")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Text variant="body2" noWrap>
          {formatUtils.formatCurrency(
            params.row.enrichedData?.["14_days_revenue"]
          )}
        </Text>
      ),
      valueGetter: (params: GridRowParams) =>
        params.row.enrichedData?.["14_days_revenue"],
    },
    {
      field: "Last 7 days revenue",
      type: "number",
      width: 150,

      sortable: false,
      align: "left",
      headerAlign: "left",
      headerName: t("last_7_days_revenue"),
      renderHeader: () => (
        <Tooltip title={t("last_7_days_revenue")}>
          <div style={{ padding: 2, overflow: "hidden" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/products/revenue.svg`}
              />
              <Text variant="body2" textType="secondary" isTableHeader>
                {t("last_7_days_revenue")}
              </Text>
            </Stack>
          </div>
        </Tooltip>
      ),
      renderCell: (params: GridRowParams) => (
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text variant="body2" noWrap>
            {formatUtils.formatCurrency(
              params.row.enrichedData?.["7_days_revenue"]
            )}
          </Text>
          <Tendances
            tendanceValues={params.row.last7DaysRevenueTendencyValues}
          />
        </Stack>
      ),
      valueGetter: (params: GridRowParams) =>
        params.row.enrichedData?.["7_days_revenue"],
    },

    {
      disableColumnMenu: true,
      field: "isDeleted",
      headerName: t("is_deleted"),
      width: 120,
      align: "right",
      sortable: false,
      renderHeader: () => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Text variant="body2" textType="secondary" isTableHeader>
            {t("is_deleted")}
          </Text>
          <Tooltip title={t("is_deleted_tooltip")}>
            <HelpOutline color={theme.customPalette.grey.light} />
          </Tooltip>
        </Stack>
      ),
      renderCell: (params: any) => {
        return (
          <>
            {params.row.isDeleted ? (
              <Tag
                customStyle={{ textTransform: "capitalize" }}
                tagVariant="error"
              >
                {t("yes")}
              </Tag>
            ) : (
              <Tag
                customStyle={{ textTransform: "capitalize" }}
                tagVariant="success"
              >
                {t("no")}
              </Tag>
            )}
          </>
        );
      },
    },
  ];
}

export default productsColumns;
