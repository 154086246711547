import { IIcon } from "./types";

const RecommendationsIcon: React.FC<IIcon> = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68965 3.82062C7.68965 2.37484 9.05423 1.47845 10.3618 1.47845H14.9048C16.2124 1.47845 17.577 2.37483 17.577 3.82062V20.1794C17.577 21.6252 16.2124 22.5216 14.9048 22.5216H10.3618C9.05424 22.5216 7.68965 21.6252 7.68965 20.1794V3.82062ZM10.3618 3.18845C9.66223 3.18845 9.39965 3.62372 9.39965 3.82062V20.1794C9.39965 20.3763 9.66223 20.8116 10.3618 20.8116H14.9048C15.6044 20.8116 15.867 20.3763 15.867 20.1794V3.82062C15.867 3.62373 15.6044 3.18845 14.9048 3.18845H10.3618Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.4541 6.32613C1.4541 5.42295 2.10029 4.43951 3.18271 4.43951H5.36672C5.83892 4.43951 6.22172 4.82231 6.22172 5.29451C6.22172 5.76672 5.83892 6.14951 5.36672 6.14951H3.21719C3.1956 6.17519 3.1641 6.23491 3.1641 6.32613V17.6739C3.1641 17.7651 3.1956 17.8249 3.21719 17.8505H5.36672C5.83892 17.8505 6.22172 18.2333 6.22172 18.7055C6.22172 19.1777 5.83892 19.5605 5.36672 19.5605H3.18271C2.10029 19.5605 1.4541 18.5771 1.4541 17.6739V6.32613Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0156 5.29451C19.0156 4.82231 19.3984 4.43951 19.8706 4.43951H22.0644C23.1457 4.43951 23.7969 5.41912 23.7969 6.32613V17.6739C23.7969 18.5809 23.1457 19.5605 22.0644 19.5605H19.8706C19.3984 19.5605 19.0156 19.1777 19.0156 18.7055C19.0156 18.2333 19.3984 17.8505 19.8706 17.8505H22.0327L22.0336 17.8494C22.0554 17.8238 22.0869 17.7645 22.0869 17.6739V6.32613C22.0869 6.23558 22.0554 6.17624 22.0336 6.15064L22.0327 6.14951H19.8706C19.3984 6.14951 19.0156 5.76672 19.0156 5.29451Z"
        fill={color}
      />
    </svg>
  );
};

export default RecommendationsIcon;
