import { IIcon } from "./types";

const LastSessionRecoveryIcon: React.FC<IIcon> = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="amend">
      <mask
        id="mask0_394_5719"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect id="Bounding box" width="24" height="24" fill={color} />
      </mask>
      <g mask="url(#mask0_394_5719)">
        <path
          id="amend_2"
          d="M7 16H17V14H7V16ZM7 12H11V10.5H9.575C9.90833 10.1833 10.2833 9.9375 10.7 9.7625C11.1167 9.5875 11.55 9.5 12 9.5C12.7833 9.5 13.4792 9.72917 14.0875 10.1875C14.6958 10.6458 15.1167 11.25 15.35 12H16.9C16.6667 10.8333 16.0917 9.875 15.175 9.125C14.2583 8.375 13.2 8 12 8C11.35 8 10.7208 8.125 10.1125 8.375C9.50417 8.625 8.96667 8.98333 8.5 9.45V8H7V12ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default LastSessionRecoveryIcon;
